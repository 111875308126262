// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Livea Font-Family **/
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');
$default-font-family: "Poppins", sans-serif;

/** Livea Colors CSS Variables **/

$livea-black: #1D1D1D;
$livea-gray-40: #5a5a5a;
$livea-gray-30: #9B9B9B;
$livea-gray-20: #D9D9D9;
$livea-gray-10: #F4F4F4;
$livea-white: #FFFFFF;

$livea-blue-dark: #306E9C;
$livea-blue: #4698DA;
$livea-blue-40: #2C7FC1;
$livea-blue-30: #378BCE;
$livea-blue-20: #DFEFFC;
$livea-blue-10: #E8F4FD;

$livea-yellow: #FFCA46;
$livea-green: #4AC248;
$livea-red: #E9372D;


/** Livea Font-Weights CSS Variables **/

$font-weight-bold: 700;
$font-weight-regular: 400;

/** Livea Font-Sizes CSS Variables **/

$font-size-bigger: 36px;
$font-size-huge: 28px;
$font-size-big: 15px;
$font-size-medium: 13px;
$font-size-small: 12px;


/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-font-family: #{$default-font-family};
  --ion-color-primary: #3880ff;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}
