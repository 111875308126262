/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";
@import "theme/variables";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


/* Null styles */

ul, li  {
  list-style: none;
  padding: 0;
  margin: 0;
}

/* Loader */

.loading-wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.73);
  .loading-spinner {
    position: relative;
    border: 6px solid $livea-gray-20; /* установка цвета серого круга */
    border-top: 6px solid $livea-blue; /* установка цвета синей полоски */
    border-radius: 50%;
    width: 48px;
    height: 48px;
    animation: spin 1.5s linear infinite;

    &:after {
      border-radius: 50%;
      width: 6px;
      height: 6px;
      content: "";
      background: $livea-blue;
      display: block;
      position: absolute;
      bottom: 30.1px;
      left: 30.3px;
    }

    &:before {
      border-radius: 50%;
      width: 6px;
      height: 6px;
      content: "";
      background: $livea-blue;
      display: block;
      position: absolute;
      bottom: 30.1px;
      left: 0.6px;
    }
  }
}

.custom-loading {
  --background: none;

  .loading-wrapper {
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
  }
}

.custom-loading.hide-loading {
  .loading-wrapper {
    animation: fade-out 0.5s ease-out forwards;
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/* Auth styles */

.auth-content {
  display: flex;

  .left-side-item {
    background: #FBFBFB;
    min-width: 460px;
    height: 100vh;
    position: relative;
    overflow: hidden;

    @media (max-width: 960px) {
      min-width: 0;
      width: 30%;
    }

    @media (max-width: 680px) {
      display: none;
    }

    &-logo {
      display: block;
      margin: 130px auto 0;
      padding: 0 5px;
      width: 22em;
      transition: 0.3s;
      @media (max-width: 960px) {
        width: 10em;
      }
    }

    .waves {
      img {
        position: absolute;
        left: 0;
        bottom: 0;
      }
    }

  }

  .auth-container {
    display: flex;
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;

    @media (max-width: 960px) {
      width: 70%;
    }

    @media (max-width: 680px) {
      width: 100%;
    }

    .auth-form {
      display: flex;
      flex-direction: column;
      width: 300px;

      &-title {
        font-weight: 700;
        font-size: 36px;
        line-height: 36px;
        text-align: center;
        color: #4698DA;
        margin-bottom: 72px;
      }

      &-inputs {
        .input-item {
          margin-bottom: 31px;
          position: relative;
          .passwords-error-message {
            position: absolute;
            left: 0;
            bottom: -17px;
            font-weight: 400;
            font-size: $font-size-small;
            line-height: 14px;
            color: $livea-red;
          }
        }
      }

      .routes {
        margin-top: 40px;
        line-height: 16px;
        font-weight: 400;
        text-align: center;
        font-size: $font-size-medium;

        &-forgot-password {
          color: $livea-blue;
          margin-bottom: 20px;
          cursor: pointer;
        }

        &-registration {
          color: $livea-gray-40;
          margin-right: 2px;
          span {
            font-size: $font-size-medium;
            color: $livea-blue;
            cursor: pointer;
          }
        }
      }
    }
  }
}

/* Page */
.page {
  background: #FBFBFB;
  width: 100%;
  min-height: 100%;
  overflow: auto;
  padding: 65px 65px 80px 65px;
  &.no-overflow {
    overflow: unset !important; // Переопределение overflow для класса .no-overflow
  }
  @media print {
    overflow: hidden;
    padding: 0;
  }

  &.with-menu {
    padding: 65px 65px 65px 180px;
    @media (max-width: 680px) {
      padding: 65px 65px 65px 110px;
    }
    @media (max-width: 440px) {
      padding: 65px 15px 65px 110px;
    }
    @media print {
      overflow: hidden;
      padding: 0;
    }
  }

  .page-title {
    font-weight: $font-weight-bold;
    font-size: $font-size-bigger;
    line-height: 36px;
    color: $livea-blue;
    margin-bottom: 40px;
    @media print {
      display: none;
    }
  }
}

/* Waves Animation */
.wave-anim {
  .img1 {
    animation: wave1 2s linear infinite;
  }
  .img2 {
    animation: wave 3.5s linear infinite;
  }
  .img3 {
    animation: wave 5s linear infinite;
  }

  @keyframes wave {
    0% { transform: translate(0px, 0px); }
    50% { transform: translate(-20px, 5px) scale(1.1); }
    100% { transform: translate(0px, 0px); }
  }

  @keyframes wave1 {
    0% { transform: translate(0px, 0px); }
    50% { transform: translate(-5px, -5px) scale(1.1); }
    50% { transform: translate(5px, 0px) scale(1.05); }
    100% { transform: translate(0px, 0px); }
  }

}

/* Alert Modal */
.alert-modal {
  .alert-wrapper {
    border-radius: 15px;
    .alert-head {
      text-align: center;
      font-weight: $font-weight-bold;
    }

    .alert-message {
      text-align: center;
      font-weight: $font-weight-regular;
      box-shadow: inset 0px -1px 0px #E4E4EF;
    }

    .alert-button-group {
      display: flex;
      justify-content: center;
      align-items: center;

      button {
        padding: 0;
        margin: 0;
        width: 100%;

        .alert-button-inner {
          color: $livea-blue;
          justify-content: center;
        }
      }
    }
  }
  &.confirm-modal {
    .alert-button-group {
      padding: 0;
      button {
        padding: 10px;
        width: 50%;
        transition: 0.3s;
        &:hover {
          background: $livea-blue-10;
        }
      }
    }
  }
}

ion-toast {
  --box-shadow: 0px 4px 15px rgba(125, 125, 125, 0.25);
  --border-radius: 8px;
  --start: auto;
  --end: 40px;
  --min-width: 260px;

  // container styles
  &::part(container) {
    background: #FFFFFF;
    border-radius: 8px;
    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0px;
      border-radius: 8px 0px 0px 8px;
      height: 100%;
      width: 6px;
    }
  }

  // default
  &.toast-type-default {
    &::part(header) {
      color: $livea-blue;
    }
    &::part(container) {
      &:before {
        background: $livea-blue;
      }
    }
  }

  // success
  &.toast-type-success {
    &::part(header) {
      color: #4AC248;
    }

    &::part(container) {
      &:before {
        background: #4AC248;
      }
    }
  }

  // danger
  &.toast-type-danger {
    &::part(header) {
      color: #E9372D;
    }
    &::part(container) {
      &:before {
        background: #E9372D;
      }
    }
  }

  // warning
  &.toast-type-warning {
    &::part(header) {
      color: #FFCA46;
    }
    &::part(container) {
      &:before {
        background: #FFCA46;
      }
    }
  }

  // header
  &::part(header) {
    font-weight: 700;
    font-size: 13px;
    line-height: 13px;
    padding-left: 10px;
  }

  // message
  &::part(message) {
    padding-top: 7px;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #696969;
    padding-left: 10px;
  }

  // button
  &::part(button) {
    padding-left: 0;
    padding-right: 0;
    width: 10px;
    top: 0px;
    right: 14px;
    height: 10px;
    position: absolute;
    overflow: inherit;
  }
}

.scroll-style {
  &::-webkit-scrollbar {
    height: 10px;
    width: 8px;
  }
  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar-track {
    background: #FBFBFB;
  }
  &::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar-thumb {
    background-color: $livea-gray-20;
    border-radius: 10px;
    border: 3px solid #FBFBFB;
  }
}

//TABS

.table-print-title {
  color: $livea-black;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
}

.container-tabs {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
  @media screen and (max-width: 690px) {
    flex-wrap: wrap;
  }

  .container-tabs-wrapper {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  .container-tab {
    cursor: pointer;
    margin-right: 15px;

    &--label {
      padding: 15px 5px;
      color: $livea-gray-30;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      user-select: none;
    }

    &--indicator {
      width: 0;
      height: 2px;
      transition: width .2s ease-in-out;
    }
  }
  .container-tab.active {
    .container-tab--label {
      color: $livea-black;
    }

    .container-tab--indicator {
      width: 100%;
      background: $livea-blue;
    }
  }
}

.tabs-content-wrapper {}

.table {

  .sticky-header-row {
    position: sticky;
    top: 0;
    background: #fff; /* Цвет фона для "липкой" части */
    z-index: 1;
  }

  .sticky-bottom-row {
    position: sticky;
    bottom: 0;
    background: #fff; /* Цвет фона для "липкой" части */
    z-index: 1;
  }

  tbody tr:last-child {
    position: sticky;
    bottom: 0;
    background: #fff; /* Цвет фона для "липкой" части */
    z-index: 1;
  }

  .column-item img, .row-item img {
    margin-top: 5px;
  }

  .table-block {
    // padding: 20px;
    background: $livea-white;
    border-radius: 8px;
    position: relative;
    display: none;
    transition: 0.3s;
    @media (max-width: 1175px) {
      overflow-x: auto;
      overflow-y: hidden;
    }
    &.show {
      display: block;
    }

    .app-loader-wrapper {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 98%;
      height: 100%;
      z-index: 2;
      opacity: 0;
      visibility: hidden;
      transition: 0.3s;
      &.show {
        opacity: 1;
        visibility: visible;
      }
    }
    table {
      width: 100%;
      font-size: 12px;
      opacity: 0.5;
      pointer-events: none;
      &.show {
        opacity: 1;
        pointer-events: auto;
      }
      @media (max-width: 1265px) {
        font-size: 10px;
        // min-width: 850px;
      }
      thead {
        box-shadow: inset 0px -1px 0px #E4E4EF;
      }
      tr {
        height: 44px;
        min-height: 44px;
        transition: background 0.3s;
        &:hover {
          background: #E4E4EF;
        }
        .first-item {
          padding-left: 20px;
          text-align: start;
          font-weight: 600;
          max-width: 125px;
          //white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          transition: 0.3s;
        }
        .row-data {
          transition: 0.3s;
        }
        .row-hide {
          cursor: pointer;
        }
        &.hide {
          .row-data, .first-item {
            opacity: 0.3;
          }
        }
      }
      tr:not(:last-child) {
        box-shadow: inset 0px -1px 0px #E4E4EF;
      }
      th, td {
        min-width: 65px;
        text-align: center;
        padding: 5px;
      }
    }
  }

  .costs {
    // padding: 20px;
    background: $livea-white;
    border-radius: 8px;
    //overflow-x: auto;
    //overflow-y: hidden;
    position: relative;
    @media (max-width: 650px) {
      overflow-x: auto;
      overflow-y: hidden;
    }
    .app-loader-wrapper {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 96%;
      height: 100%;
      z-index: 2;
      opacity: 0;
      visibility: hidden;
      transition: 0.3s;
      &.show {
        opacity: 1;
        visibility: visible;
      }
    }

    table {
      width: 100%;
      font-size: 12px;
      opacity: 0.5;
      pointer-events: none;
      &.show {
        opacity: 1;
        pointer-events: auto;
      }
      input {
        text-align: center;
        outline: none;
        border: none;
        pointer-events: none;
        border-bottom: 1px solid transparent;
        transition: background 0.3s;
        &:hover {
          border-bottom: 1px solid $livea-blue;
        }
        &:focus {
          border-bottom: 1px solid $livea-blue;
        }
        &.editable {
          border-bottom: 1px solid $livea-blue;
          pointer-events: auto;
        }
        &.error {
          border-bottom: 1px solid $livea-red;
          pointer-events: auto;
        }
      }
      thead {
        box-shadow: inset 0px -1px 0px #E4E4EF;
      }
      .first-item {
        text-align: start !important;
        font-weight: 600;
        max-width: 125px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        transition: 0.3s;
        input {
          text-align: start;
        }
      }
      .row-edit {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 20px;
        min-width: 130px;
        padding-right: 20px;
        .row-edit-btn {
          cursor: pointer;
          padding: 5px 0;
          opacity: 0.65;
          transition: 0.3s;
          &:hover {
            opacity: 1;
          }
        }
        .btn-icon {
          cursor: pointer;
          width: 30px;
          height: 30px;
          flex-shrink: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 8px;
          background: var(--white, $livea-white);
          box-shadow: 0px 4px 15px 0px rgba(195, 195, 195, 0.15);
          transition: box-shadow 0.25s;
          &:hover {
            box-shadow: 0px 4px 15px 0px rgba(195, 195, 195, 0.45);
          }
          .btn-icon-add-img {
            margin-top: 0.5px;
            max-width: 20px;
            margin-left: 0.5px;
          }
        }
      }
      tr {
        height: 44px;
        min-height: 44px;
        transition: background 0.3s;
        &:hover {
          background: #E4E4EF;
          input {
            background: #E4E4EF;
          }
        }
      }
      tr:not(:last-child) {
        box-shadow: inset 0px -1px 0px #E4E4EF;
      }
      th, td {
        min-width: 65px;
        text-align: center;
        padding: 5px;
      }
    }
  }
}

.row-item {
  &.bold {
    font-weight: 600;
  }
}

.line-buttons {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 30px;
  &.space-between {
    justify-content: space-between;
  }

  .line-buttons-item {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 20px;
  }
  @media (max-width: 825px) {
    margin: 5px;
  }

  .line-button {
    min-width: 140px;
    @media (max-width: 825px) {
      margin: 5px;
    }
  }
}
